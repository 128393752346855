import React, { useEffect, useRef } from 'react';
import { AUTHENTICATED } from 'lib/myNewsConstants';
import PropTypes from 'prop-types';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { GATED_CONTENT } from 'lib/brandFeatures';
import { useGeoLocation, useVertical } from 'lib/Hooks';
import { getAuthenticationState, isBedrockApiEnabled } from 'lib/authenticationUtils';
import { Gate } from 'components/Gate';
import { GATE_BANNER_TITLES, GATE_TITLES, GATE_SUBTITLES } from 'components/Gate/GateTextConstants';
import { stub as $t } from '@nbcnews/analytics-framework';
import { AccountLoginRegistration } from 'components/AccountLoginRegistration';
import throttle from 'lodash.throttle';
import styles from './styles.module.scss';
import { useMyNewsStore, useBedrockRegistration } from '../../store';

$t('register', 'mbt_gate_click');


/**
 * GatedFrontPage component
 *
 * This component renders a gated front page that either shows a login/registration form
 * or a gate banner based on the user's authentication state and feature flags.
 *
 * @param {object} props - The component props
 * @param {string} props.pageRoute - The current page route
 * @returns {JSX.Element|null} The rendered component or null if the gate should not be shown
 */
const GatedFrontPage = ({ pageRoute }) => {
  const authenticationState = getAuthenticationState();
  const authenticate = useMyNewsStore((state) => state.authenticate);
  const showBedrockGate = useBedrockRegistration((state) => state.showGate);
  const showAuthenticationModal = useBedrockRegistration((state) => state.showAuthenticationModal);

  const launchDarklyFlags = useFeatureFlagContext();
  const gateFrontPageFlag = launchDarklyFlags?.['today-gated-front'];

  const ref = useRef(null);

  const handleScroll = throttle(() => {
    if (!ref.current) return;
    const footerTopPosition = document.getElementById('hfs-footer')?.getBoundingClientRect().top;
    ref.current.style.position = footerTopPosition <= window.innerHeight ? 'absolute' : 'fixed';
  }, 10);

  useEffect(() => {
    const layoutContainers = document.querySelectorAll('.layout-container a');
    const tabindex = authenticationState !== AUTHENTICATED ? '-1' : '0';

    layoutContainers.forEach((a) => a.setAttribute('tabindex', tabindex));

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [authenticationState]);

  const { vertical } = useVertical();
  const hasGatedContentFeature = getFeatureConfigForBrand(
    GATED_CONTENT,
    vertical,
  );


  /**
   * Tracks user actions on the gated front page.
   *
   * @param {string} action - The action performed by the user. Possible values are 'signup start' or 'login start'.
   */
  const track = (action) => {
    $t('track', 'mbt_gate_click', {
      action, // 'signup start' or 'login start' values to be used
      gatetype: 'inline', // this indicates the placement of the CTA.
    });
  };

  const { isUsa } = useGeoLocation();
  const notAuthenticated = isBedrockApiEnabled()
    ? showBedrockGate : authenticationState !== AUTHENTICATED;
  const isNewLoginFlow = isBedrockApiEnabled();
  const showGate = (pageRoute && pageRoute === '/start-today' && hasGatedContentFeature)
      && notAuthenticated && gateFrontPageFlag;
  const showLogin = showGate && isUsa && isNewLoginFlow;
  if (!showGate) return null;

  const showGateOrLogin = showLogin ? (
    <div className={styles.accountBanner}>
      <AccountLoginRegistration hideScreen={showAuthenticationModal} />
    </div>
  ) : (
    <div className={styles.banner}>
      <Gate
        bannerTitle={GATE_BANNER_TITLES.front}
        title={GATE_TITLES.front}
        subtitle={GATE_SUBTITLES.front}
        className={styles.gate}
        variant="sticky"
      />
    </div>
  );

  /**
   * Handles authentication click event
   */
  const handleOnClick = () => {
    // add condition to authenticate with bedrock auth when ready
    if (showGate) {
      authenticate({ defaultPage: 'createProfile' });
      track('signup start');
    }
  };

  return (
    <div
      className={styles.container}
      data-testid="gated-front-page"
      ref={ref}
    >
      <div
        className={styles.backdrop}
        aria-label="Trigger Signup"
        role="button"
        onKeyDown={() => {}}
        tabIndex="0"
        onClick={handleOnClick}
      />
      {showGateOrLogin}
    </div>
  );
};

GatedFrontPage.propTypes = {
  pageRoute: PropTypes.string.isRequired,
};

export default GatedFrontPage;
