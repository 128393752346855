// [SB] (06/13/18)
// Tried to clean this up a bit but it's out of scope of work.
// The proper way to do this is to have a "current" redux key
// that holds the props for ad targeting and upon transporter or navigation
// dispatch an update action with the content type. This would allow you to remove
// all of the passing around "getProps" and simplify code/readability.

function getPropsForContent(content) {
  const {
    taxonomy,
    url,
    nativeAd,
  } = content || {};

  const {
    primaryVertical,
    primarySubvertical,
    primaryTopic,
    primarySection,
    primaryShow,
    series,
  } = taxonomy || {};

  // Will be cleaned up during the new ad path project.
  const section = (primarySubvertical?.slug)
    || (primarySection?.slug)
    || null;

  const props = {
    isNativeArticle: nativeAd,
    vertical: primaryVertical?.slug,
    topic: primaryTopic?.slug,
    section,
    show: primaryShow?.slug,
    series: series?.[0]?.slug,
    canonicalUrl: url?.primary,
  };

  return props;
}

export default function mpsMapStoreToProps({
  shared = {},
  router = {},
  article = {},
  video = {},
  slideshow = {},
  liveBlog = {},
} = {}) {
  const def = {
    pageView: shared.pageView,
    currentPath: router.path,
    vertical: shared.vertical,
    section: shared.section,
    isChromeless: shared.isChromeless,
    article: article?.content?.[0],
  };

  switch (shared.pageView) {
    case 'article': {
      const content = article.content?.[0];
      return {
        ...def,
        pageView: 'article',
        article: content,
        ...getPropsForContent(content),
      };
    }

    case 'video':
      return {
        ...def,
        pageView: 'video',
        video: video.current,
        ...getPropsForContent(video.current),
      };

    case 'slideshow':
      return {
        ...def,
        pageView: 'slideshow',
        slideshow: slideshow.current,
        ...getPropsForContent(slideshow.current),
      };

    case 'card':
      return {
        ...def,
        pageView: 'card',
        card: liveBlog.card,
        ...getPropsForContent(liveBlog.card),
      };

    default:
      return def;
  }
}
